<template>
  <div class="container">
    <div class="div1">领导关怀</div>
    <div class="div2">Social responsibility</div>

    <div class="one">
      <div class="two" :style="{backgroundImage:'url('+item.pic+')' }" v-for="(item,index) in data">
        <div class="three">
          <div class="four">{{item.subtitle}}</div>
          <div class="five"></div>
          <div class="six" v-html="delHtmlTag(item.title.substr(0, 18))" ></div>
          <div class="seven">{{item.title.substr(18, 38)}}</div>
        </div>
      </div>
    </div>
    <div class="bottom">

      <el-pagination

              :hide-on-single-page="value"
              @current-change="updated"
              background
              layout=" pager"
              :total="total"
              :current-page.sync="num"
              :page-size = "pageSize"
      >
      </el-pagination>
    </div>

  </div>
</template>

<script>
  import { getLeadershipList } from "../../api/zoujindaweijia";
  export default {
    data() {
      return {
        value: true,
        data: [],
        num:1,
        total:0,
        pageSize:10,
      };
    },
    methods: {
      delHtmlTag(str){
        if(str!= null){
          return str.replace(/<[^>]+>/g,"");
        }
      },
      updated(newPage) {
        getLeadershipList( {
          page:this.num,
          limit:this.pageSize,
          // type:0
        }).then((res) => {
          console.log(res);
          let data = res.data.data;
          //获取后台总数据量
          this.total = res.data.total;
          // console.log(res.data)
          // console.log(data);
          this.data = data;
          // console.log(this.data)
        });
      },
    },
    mounted() {
      getLeadershipList( {
        page:this.num,
        limit:this.pageSize,
        // type:0
      }).then((res) => {
        // console.log(res.data.data);
        let data = res.data.data;
        //获取后台总数据量
        this.total = res.data.total;
        // console.log(res.data)
        this.data = data;
        // console.log(this.data)
      });

    },
  };
</script>

<style lang="less" scoped>
  .container {
    /*height: 82.8646vw;*/
  }
  .div1 {
    height: 2.0833vw;
    font-size: 2.2917vw;
    font-family: PingFang SC, PingFang SC-Bold;
    font-weight: 700;
    color: #d7000f;
    margin-top: 4.1146vw;
    text-align: center;
    letter-spacing:0.15vw;
  }
  .div2 {
    height: 1.0417vw;
    font-size: 1.3542vw;
    font-family: PingFang SC, PingFang SC-Regular;
    font-weight: 400;
    color: #646464;
    margin-top: 1.0417vw;
    text-align: center;
  }
  .one {
    margin-top: 5.2083vw;
    margin-bottom: 5vw;
    margin-left: 8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    /*display: grid;*/
    /*grid-template-rows: 29vw 29vw 29vw 29vw;*/
    /*grid-template-columns: 42vw 42vw;*/
    /*grid-gap: 2.6042vw 2.6042vw;*/
  }
  .two {
    width: 42vw;
    height: 29vw;
    /*background-color: pink;*/
    background-repeat: repeat-x;
    background-size: 100% 100%;

    margin: 0  0 1.5625vw 1.5625vw;
    /*width: calc((100% - 0.5208vw) / 5);*/
    min-width: calc((100% - 10vw) / 2);
    max-width: calc((100% - 10vw) / 2);
  }
  .three {
    display: flex;
    background-color: red;
    width: 11.1979vw;
    opacity: 0.7;
    visibility: hidden;
    height: 100%;
  }
  .four {
    width: 4.4792vw;
    font-size: 2.0833vw;
    font-family: Arial, Arial-Bold;
    font-weight: 700;
    color: #ffffff;
    margin-right: 0.7813vw;
    margin-top: 1vw;
  }
  .five {
    width: 0.0521vw;
    height: 1.7188vw;
    background-color: #fff;
    margin-right: 0.7813vw;
    margin-top: 1vw;
  }
  .six {
    width: 1.54vw;
    height: 99%;
    font-size: 1.25vw;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: white;
    line-height: 1.5vw;
    margin-top: 1vw;
    overflow: hidden;
    margin-right:0.7vw;
    word-break:break-word;
  }
  .seven {
    width: 1.7604vw;
    height: 100%;
    font-size: 1.25vw;
    font-family: PingFang SC, PingFang SC-Medium;
    font-weight: 500;
    color: #ffffff;
    line-height: 1.5vw;
    margin-top: 1vw;
    overflow: hidden;
    word-break:break-word;

  }

  .two:hover .three {
    visibility: visible;
  }

  .bottom{
    margin-left: 70vw;
  }

  /deep/ .el-pager {
    .number {
      margin: 0 10px;
      border-radius: 100% !important;
      color: #000000;
      border: 1px solid #d8d8d8;
      font-weight: 400;
    }
    .active {
      color: #fae9e9;
      background-color: #d7000f !important;
    }
  }
  .el-pagination{
    margin-bottom: 4vw;
    /*background-color: green;*/
  }
</style>
